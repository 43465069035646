import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";

const Scoring = ({ poolScoring }) => {
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const getScoringExplanation = (type) => {
    switch (type) {
      case "Date":
        return "The score will be subtracted by 1 for each day your guess is off.";
      case "Time":
        return "The score will be subtracted by 1 for each hour your guess is off.";
      case "Weight (kg)":
      case "Weight (lb)":
      case "Length (in)":
      case "Length (cm)":
      case "Name Length":
        return "The score will be subtracted by the difference between the correct score and the guess.";
      case "Sex":
      case "Letter":
      case "Hair Color":
      case "Eye Color":
        return "It needs to be an exact match to score points.";
      default:
        return "";
    }
  };

  return (
    <Card sx={{ m: 2, boxShadow: 3 }}>
      <CardContent>
        <Typography
          variant="h5"
          gutterBottom
          component="div"
          sx={{ textAlign: "center", mb: 3 }}
        >
          Scoring
        </Typography>
        <Typography variant="subtitle1" sx={{ textAlign: "center", mb: 2 }}>
          Points Breakdown
        </Typography>
        <List>
          {poolScoring
            .sort((a, b) => b.score - a.score)
            .map((score, index) => (
              <ListItem key={index} divider>
                <ListItemText
                  primary={capitalizeFirstLetter(score.category)}
                  secondary={`${score.type}: ${getScoringExplanation(
                    score.type
                  )}`}
                />
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography variant="body2">{score.score}</Typography>
                </Box>
              </ListItem>
            ))}
        </List>
      </CardContent>
    </Card>
  );
};

export default Scoring;
