export const loginSuccess = (userData) => {
  return {
    type: "LOGIN_SUCCESS",
    payload: { ...userData },
  };
};

export const logout = () => {
  return {
    type: "LOGOUT",
  };
};

//slices
export const fetchUser = (state) => state.user.user;
export const fetchUserId = (state) =>
  state.user.user ? state.user.user._id : null;
