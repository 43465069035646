import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  IconButton,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
  Grid,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import { API_URL } from "../../utils/constants";
import { useSelector } from "react-redux";
import { fetchUser } from "../../redux/actions/user";
import { useNavigate } from "react-router-dom";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import Link from "@mui/material/Link";
import Tooltip from "@mui/material/Tooltip";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import QRCode from "qrcode.react";

const AdminDetails = ({ poolDetails }) => {
  const [open, setOpen] = useState(false);
  const user = useSelector(fetchUser);

  const navigate = useNavigate();

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };

    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const onDelete = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onEdit = () => {
    navigate("/create", { state: { poolDetails: poolDetails } });
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(
      `https://babypool.io/${poolDetails.uniqueId}`
    );
  };

  const handleConfirmDelete = async () => {
    handleClose();
    try {
      await axios.delete(`${API_URL}/api/v0/pools/${poolDetails._id}`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      navigate("/pools");
    } catch (error) {
      console.error("Error deleting pool:", error);
    }
  };
  return (
    <Card sx={{ m: 2, position: "relative", height: 225 }}>
      <CardContent>
        <Grid container>
          <Grid item xs={6}>
            <Typography variant="h5" gutterBottom>
              Details
            </Typography>
            <Typography variant="body1">{poolDetails.name}</Typography>
            <Typography variant="body2" sx={{ mt: 1 }}>
              {poolDetails.description}
            </Typography>
            <Typography variant="body2" sx={{ mt: 2, fontStyle: "italic" }}>
              Due Date: {formatDate(poolDetails.dueDate)}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Box sx={{ position: "absolute", top: 10, right: 10 }}>
              {" "}
              <Tooltip title="Copy link">
                <IconButton onClick={copyToClipboard} color="primary">
                  <FileCopyIcon />
                </IconButton>
              </Tooltip>
              <IconButton onClick={onEdit} color="#4FD1C5">
                <EditIcon />
              </IconButton>
              <IconButton onClick={onDelete} color="error">
                <DeleteIcon />
              </IconButton>
              <Link
                href={`https://babypool.io/${poolDetails.uniqueId}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <IconButton color="primary">
                  <OpenInNewIcon />
                </IconButton>
              </Link>
              <Box
                sx={{ alignSelf: "flex-end", overflow: "visible", padding: 2 }}
              >
                {/* Display QR Code */}
                <QRCode value={`https://babypool.io/${poolDetails.uniqueId}`} />
                {/* Uncomment below line if using a static image */}
                {/* <img src={qrCodeImage} alt="QR Code" /> */}
              </Box>
            </Box>{" "}
          </Grid>
        </Grid>
      </CardContent>

      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this pool?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleConfirmDelete} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default AdminDetails;
