import React from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { login } from "../../utils/api"; // Make sure you have a loginUser function
import { useDispatch } from "react-redux";
import { loginSuccess } from "../../redux/actions/user";
import { useNavigate } from "react-router-dom";
import Socials from "./Socials";

const schema = yup
  .object({
    email: yup
      .string()
      .email("Invalid email format")
      .required("Email is required"),
    password: yup
      .string()
      .min(8, "Password must be at least 8 characters long")
      .required("Password is required"),
  })
  .required();

function Login() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    const response = await login(data.email, data.password); // Adjust according to your API
    if (response.user) {
      dispatch(loginSuccess(response.user));
      navigate("/pools"); // Redirect after successful login
    }
  };
  const isDisabled = Object.keys(errors).length > 0;

  return (
    <Box
      sx={{
        padding: isMobile ? 2 : 4,
        textAlign: "center",
        backgroundColor: "#f0f0f0",
        minHeight: isMobile ? "50vh" : "65vh",
        backgroundImage: "url(/stork-bg.png)",
        backgroundSize: isMobile ? "cover" : "contain",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        color: "#000",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: 1,
        }}
      />
      <Box
        sx={{
          maxWidth: { sm: "90%", md: "75%", lg: "60%" },
          textAlign: "center",
          position: "relative",
          zIndex: 2,
        }}
      >
        <Typography
          variant="h4"
          sx={{
            marginBottom: 2,
            color: "white",
            textShadow: "1px 1px 4px rgba(0,0,0,0.7)",
          }}
        >
          Login to Baby Pool
        </Typography>{" "}
        <form onSubmit={handleSubmit(onSubmit)} style={{ marginBottom: 30 }}>
          <TextField
            label="Email Address"
            variant="filled"
            fullWidth
            {...register("email")}
            error={!!errors.email}
            helperText={errors.email?.message}
            InputLabelProps={{ style: { color: "white" } }}
            inputProps={{ style: { color: "white" } }}
            sx={{
              backgroundColor: "transparent",
              marginBottom: 2,
            }}
          />
          <TextField
            label="Password"
            variant="filled"
            type="password"
            fullWidth
            {...register("password")}
            error={!!errors.password}
            helperText={errors.password?.message}
            InputLabelProps={{ style: { color: "white" } }}
            inputProps={{ style: { color: "white" } }}
            sx={{
              backgroundColor: "transparent",
              marginBottom: 2,
            }}
          />
          <Button
            variant="contained"
            color="primary"
            size="large"
            type="submit"
            disabled={isDisabled}
            sx={{ width: 185 }}
          >
            Login
          </Button>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            margin={2}
            // height="100vh"
          >
            <Socials />
          </Box>
        </form>
      </Box>
    </Box>
  );
}

export default Login;
