import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  Grid,
  TextField,
  useTheme,
  useMediaQuery,
} from "@mui/material";

function AboutUsCTA({ scrollToContact }) {
  const [poolCode, setPoolCode] = useState("");

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleSearch = (e) => {
    e.preventDefault();
    window.location.href = `/${poolCode}`;
  };

  return (
    <Box
      sx={{
        padding: isMobile ? 2 : 4,
        textAlign: "center",
        backgroundColor: "#f0f0f0",
        minHeight: isMobile ? "50vh" : "65vh",
        backgroundImage: "url(/stork-bg.png)",
        backgroundSize: isMobile ? "cover" : "contain",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        color: "#000",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
      }}
    >
      {/* <Box
        component="img"
        sx={{
          width: "100%",
          height: "auto",
          maxHeight: { xs: "60vh", sm: "45vh", md: "30vh" },
          objectFit: "contain",
          marginBottom: 3,
          opacity: 0.5, // Adjust opacity
          boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
        }}
        alt="Becoming Mountains Logo"
        src="/logo-large.png" // A larger version of your logo
      />{" "} */}
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.5)", // Adjust the color and opacity as needed
          zIndex: 1,
        }}
      />
      <Box
        sx={{
          maxWidth: { sm: "90%", md: "75%", lg: "60%" },
          textAlign: "center",
          position: "relative", // Ensure this box is above the overlay
          zIndex: 2,
        }}
      >
        <Typography
          variant="h4"
          sx={{
            marginBottom: 2,
            color: "white",
            textShadow: "1px 1px 4px rgba(0,0,0,0.7)",
          }}
        >
          Baby Pool
        </Typography>
        <Typography
          variant="h6"
          sx={{
            marginBottom: 3,
            color: "white",
            textShadow: "1px 1px 4px rgba(0,0,0,0.7)",
          }}
        >
          Join the fun of guessing in our baby pools!
        </Typography>

        <form onSubmit={handleSearch} style={{ marginBottom: 30 }}>
          <TextField
            label="Enter Pool Code"
            variant="filled"
            value={poolCode}
            onChange={(e) => setPoolCode(e.target.value)}
            sx={{ backgroundColor: "white", borderRadius: 1, marginRight: 1 }}
          />
          <Button
            variant="contained"
            color="primary"
            type="submit"
            sx={{ height: 54 }}
          >
            Search
          </Button>
        </form>
        <Grid container justifyContent="center">
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={scrollToContact}
          >
            Contact Us
          </Button>
        </Grid>
      </Box>
    </Box>
  );
}

export default AboutUsCTA;
