import React, { useCallback, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Alert,
  Snackbar,
  Button,
  Grid,
  TextField,
  Dialog,
  DialogTitle,
  DialogActions,
} from "@mui/material";
import axios from "axios";
import { API_URL } from "../../utils/constants";
import { useSelector } from "react-redux";
import { fetchUser } from "../../redux/actions/user";
import AdminDetails from "./AdminDetails";
import Scoring from "../Scoring";
import AdminGuesses from "./AdminGuesses";

const AdminPool = () => {
  const { id } = useParams();
  const user = useSelector(fetchUser);

  const navigate = useNavigate();
  const [poolDetails, setPoolDetails] = useState({
    name: "",
    description: "",
    dueDate: "",
    scoring: [],
  });
  const [showPasswordInput, setShowPasswordInput] = useState(false);
  const [password, setPassword] = useState("");
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage] = useState("");
  const [errorModalOpen, setErrorModalOpen] = useState(false);

  const fetchPoolDetails = useCallback(
    async (password = "") => {
      try {
        const response = await axios.post(`${API_URL}/api/v0/pools/${id}`, {
          password,
        });
        setPoolDetails(response.data);

        setShowPasswordInput(false);
      } catch (error) {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.data.passwordRequired
        ) {
          setShowPasswordInput(true);
        } else {
          console.error("Error fetching pool details:", error);
          setErrorModalOpen(true);
        }
      }
    },
    [id]
  );

  const handleCloseErrorModal = () => {
    setErrorModalOpen(false);
    navigate("/");
  };

  useEffect(() => {
    fetchPoolDetails();
  }, [fetchPoolDetails]);

  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    fetchPoolDetails(password);
  };

  useEffect(() => {
    if (!user) {
      navigate("/");
    }
  });

  if (!poolDetails) {
    return <div>Loading pool details...</div>;
  }
  console.log(poolDetails);
  return (
    <Grid container direction="column">
      <Grid item>
        <AdminDetails poolDetails={poolDetails} />
      </Grid>{" "}
      <Grid item>
        <AdminGuesses poolDetails={poolDetails} />
      </Grid>
      <Grid item>
        <Scoring poolScoring={poolDetails.scoring} />
      </Grid>
      {showPasswordInput && (
        <div className="password-overlay">
          <form onSubmit={handlePasswordSubmit} className="password-form">
            <div className="password-input-group">
              <TextField
                label="Password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                autoFocus
                sx={{ marginRight: 2, flex: 1 }}
              />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{ flexShrink: 0, marginLeft: 5 }}
              >
                Submit
              </Button>{" "}
            </div>
          </form>
        </div>
      )}
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={() => setAlertOpen(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={() => setAlertOpen(false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
      <Dialog
        open={errorModalOpen}
        onClose={handleCloseErrorModal}
        aria-labelledby="error-dialog-title"
        PaperProps={{ style: modalStyle.dialog }}
      >
        <DialogTitle id="error-dialog-title" style={modalStyle.title}>
          Error
        </DialogTitle>
        <Alert severity="error" style={modalStyle.alert}>
          Could not find pool
        </Alert>
        <DialogActions>
          <Button onClick={handleCloseErrorModal} style={modalStyle.button}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

const modalStyle = {
  dialog: {
    padding: "20px",
    minWidth: "300px", // Adjust as needed
  },
  title: {
    paddingBottom: "10px",
  },
  alert: {
    marginBottom: "20px",
  },
  button: {
    color: "white",
    backgroundColor: "#1976d2", // Or your primary color
    "&:hover": {
      backgroundColor: "#115293", // Darker shade for hover effect
    },
  },
};

export default AdminPool;
