import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Provider } from "react-redux";
import { Box, useTheme } from "@mui/material";
import { GoogleOAuthProvider } from "@react-oauth/google";

import CreatePools from "./components/Admin/CreatePool";
import AdminPool from "./components/Admin/AdminPool";
import "./App.css";
import PoolDetails from "./pages/PoolDetails";
import LandingPage from "./components/LandingPage/LandingPage2";
import Login from "./components/Admin/Login";
import SignUp from "./components/Admin/SignUp";
import Header from "./components/LandingPage/Header";
import { store } from "./redux/store";
import Pools from "./components/Admin/Pools";

function App() {
  const theme = useTheme();
  return (
    <Provider store={store}>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
        <Router>
          <div className="App">
            <Header />
            <Box sx={{ paddingTop: theme.spacing(12) }}>
              <Routes>
                <Route path="/" element={<LandingPage />} />
                <Route path="/login" element={<Login />} />
                <Route path="/signup" element={<SignUp />} />
                <Route path="/pools" element={<Pools />} />
                <Route path="/pools/:id" element={<AdminPool />} />
                <Route path="/create" element={<CreatePools />} />

                <Route path="/:id" element={<PoolDetails />} />
              </Routes>
            </Box>
          </div>
        </Router>
      </GoogleOAuthProvider>
    </Provider>
  );
}

export default App;
