import axios from "axios";
import { API_URL } from "./constants";

export const registerUser = async (userData) => {
  try {
    const response = await axios.post(
      `${API_URL}/api/v0/users/register`,
      userData
    );
    return response.data;
  } catch (error) {
    console.error("Error:", error);
  }
};

export const login = async (email, password) => {
  try {
    const response = await axios.post(`${API_URL}/api/v0/users/login`, {
      username: email,
      password,
    });
    return response.data;
  } catch (error) {
    console.log("Error with login:", error);
  }
};
