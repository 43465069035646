import React, { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Card,
  CardContent,
  Grid,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const AdminGuesses = ({ poolDetails }) => {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString(undefined, {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const formatTime = (timeString) => {
    const time = new Date("1970-01-01T" + timeString + "Z");
    return time.toLocaleTimeString(undefined, {
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  const renderWeightInPounds = (weight) => {
    return `${weight.pounds} lbs ${weight.ounces} oz`;
  };

  return (
    <Card sx={{ m: 2, position: "relative" }}>
      <CardContent>
        <Typography variant="h5" gutterBottom>
          Guesses
        </Typography>
        {poolDetails &&
          poolDetails.guesses &&
          poolDetails.guesses.map((guess, index) => (
            <Accordion
              key={index}
              expanded={expanded === `panel${index}`}
              onChange={handleChange(`panel${index}`)}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>{guess.name}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container direction="column">
                  <Grid container sx={{ marginBottom: 1 }}>
                    <Grid item xs={4} sx={{ padding: 1 }}>
                      <Typography sx={{ fontWeight: "bold" }}>Label</Typography>
                    </Grid>
                    <Grid item xs={4} sx={{ padding: 1 }}>
                      <Typography sx={{ fontWeight: "bold" }}>Type</Typography>
                    </Grid>
                    <Grid item xs={4} sx={{ padding: 1 }}>
                      <Typography sx={{ fontWeight: "bold" }}>Guess</Typography>
                    </Grid>
                  </Grid>
                  {guess &&
                    guess.guesses &&
                    guess.guesses.map((item, idx) => (
                      <Grid
                        container
                        item
                        key={idx}
                        sx={{
                          padding: 1,
                        }}
                      >
                        <Grid item xs={4}>
                          <Typography sx={{ fontWeight: "bold" }}>
                            {item.category}
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography sx={{ fontStyle: "italic" }}>
                            {item.valueType}
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography xs={{ marginLeft: 1 }}>
                            {item.valueType === "Date"
                              ? formatDate(item.value)
                              : item.valueType === "Time"
                              ? formatTime(item.value)
                              : item.valueType === "Weight (in lb)"
                              ? renderWeightInPounds(item.value)
                              : item.valueType === "Weight (kg)"
                              ? `${item.value} kg`
                              : item.valueType === "Length (in)"
                              ? `${item.value} in`
                              : item.valueType === "Length (cm)"
                              ? `${item.value} cm`
                              : item.valueType === "Name Length"
                              ? item.value === 1
                                ? `${item.value} letter`
                                : `${item.value} letters`
                              : item.value}
                          </Typography>{" "}
                        </Grid>
                      </Grid>
                    ))}
                </Grid>
              </AccordionDetails>
            </Accordion>
          ))}
      </CardContent>
    </Card>
  );
};

export default AdminGuesses;
