import { GoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { API_URL } from "../../utils/constants";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { loginSuccess } from "../../redux/actions/user";

function Socials() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const responseMessage = async (response) => {
    try {
      const res = await axios.post(`${API_URL}/api/v0/users/verify-google`, {
        client_id: response.clientId,
        jwtToken: response.credential,
      });
      if (res.data.user) {
        dispatch(loginSuccess(res.data.user));
        navigate("/pools");
      }
    } catch (err) {
      console.log(err);
    }
  };
  const errorMessage = (error) => {
    console.log(error);
  };

  return <GoogleLogin onSuccess={responseMessage} onError={errorMessage} />;
}
export default Socials;
