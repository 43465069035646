import React, { useCallback, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Scoring from "../components/Scoring";
import {
  Alert,
  Snackbar,
  Button,
  Grid,
  TextField,
  Dialog,
  DialogTitle,
  DialogActions,
} from "@mui/material";
import Details from "../components/Details";
import axios from "axios";
import { API_URL } from "../utils/constants";
import "./poolDetails.css";
import GuessForm from "../components/GuessForm";

const PoolDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [poolDetails, setPoolDetails] = useState({
    name: "",
    description: "",
    dueDate: "",
    scoring: [],
  });
  const [showPasswordInput, setShowPasswordInput] = useState(false);
  const [password, setPassword] = useState("");
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [submittedSuccess, setSubmittedSuccess] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);

  const fetchPoolDetails = useCallback(
    async (password = "") => {
      try {
        const response = await axios.post(`${API_URL}/api/v0/pools/${id}`, {
          password,
        });
        setPoolDetails(response.data);

        setShowPasswordInput(false);
      } catch (error) {
        if (
          error.response &&
          error.response.status === 401 &&
          error.response.data.passwordRequired
        ) {
          setShowPasswordInput(true);
        } else {
          console.error("Error fetching pool details:", error);
          setErrorModalOpen(true);
        }
      }
    },
    [id]
  );

  const handleCloseErrorModal = () => {
    setErrorModalOpen(false);
    navigate("/");
  };

  useEffect(() => {
    fetchPoolDetails();
  }, [fetchPoolDetails]);

  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    fetchPoolDetails(password);
  };

  const handleGuessSubmit = async (guessData) => {
    try {
      const response = await axios.post(
        `${API_URL}/api/v0/pools/${poolDetails.uniqueId}/guess`,
        guessData
      );

      if (response.status === 201) {
        setSubmittedSuccess(true);
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        const confirmOverwrite = window.confirm(
          "You have already made a guess. Do you want to overwrite your previous guess?"
        );
        if (confirmOverwrite) {
          updateGuess(guessData);
        } else {
          return "cancelled update";
        }
      } else {
        console.error("Error submitting guess:", error);
        setAlertMessage("Failed to submit guess");
        setAlertOpen(true);
        return error;
      }
    }
  };

  const updateGuess = async (guessData) => {
    try {
      const response = await axios.put(
        `${API_URL}/api/v0/pools/${poolDetails.uniqueId}/guess`,
        guessData
      );
      if (response.status === 200) {
        setSubmittedSuccess(true);
      }
    } catch (error) {
      console.error("Error updating guess:", error);
      setAlertMessage("Failed to update guess");
      setAlertOpen(true);
      return error;
    }
  };

  if (!poolDetails) {
    return <div>Loading pool details...</div>;
  }

  return (
    <Grid container direction="column">
      <Grid item>
        <Details poolDetails={poolDetails} />
      </Grid>
      <Grid item>
        <Scoring poolScoring={poolDetails.scoring} />
      </Grid>
      <Grid item>
        {submittedSuccess ? (
          <Alert severity="success" sx={{ margin: 2 }}>
            Guess submitted successfully!
          </Alert>
        ) : (
          <GuessForm
            onGuessSubmit={handleGuessSubmit}
            poolDetails={poolDetails}
          />
        )}
      </Grid>
      {showPasswordInput && (
        <div className="password-overlay">
          <form onSubmit={handlePasswordSubmit} className="password-form">
            <div className="password-input-group">
              <TextField
                label="Password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                autoFocus
                sx={{ marginRight: 2, flex: 1 }}
              />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{ flexShrink: 0, marginLeft: 5 }}
              >
                Submit
              </Button>{" "}
            </div>
          </form>
        </div>
      )}
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={() => setAlertOpen(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={() => setAlertOpen(false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>

      <Dialog
        open={errorModalOpen}
        onClose={handleCloseErrorModal}
        aria-labelledby="error-dialog-title"
        PaperProps={{ style: modalStyle.dialog }}
      >
        <DialogTitle id="error-dialog-title" style={modalStyle.title}>
          Error
        </DialogTitle>
        <Alert severity="error" style={modalStyle.alert}>
          Could not find pool
        </Alert>
        <DialogActions>
          <Button onClick={handleCloseErrorModal} style={modalStyle.button}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

const modalStyle = {
  dialog: {
    padding: "20px",
    minWidth: "300px", // Adjust as needed
  },
  title: {
    paddingBottom: "10px",
  },
  alert: {
    marginBottom: "20px",
  },
  button: {
    color: "white",
    backgroundColor: "#1976d2", // Or your primary color
    "&:hover": {
      backgroundColor: "#115293", // Darker shade for hover effect
    },
  },
};

export default PoolDetails;
