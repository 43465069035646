import React, { useState } from "react";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function FAQ() {
  const [expanded, setExpanded] = useState(false);

  const faqs = [
    {
      question: "What is a baby pool?",
      answer:
        "A baby pool is a fun guessing game where friends and family predict details about a baby's birth, such as sex, date and time, weight, and length.",
    },
    {
      question: "How do you participate in a baby pool?",
      answer:
        "To participate, you simply make your guesses based on the categories provided, which may include the baby's birth date, weight, length, and other fun categories like hair color or the first letter of the baby's name.",
    },
    {
      question: "Is it free to participate in baby pool?",
      answer:
        "Participating in a baby pool is completely free. You can submit your guesses without any charge. Creating a baby pool is also free but comes with certain limitations. For instance, the number of categories you can create may be limited, and the guessing page may include ads.",
    },
    {
      question: "Is there a premium version for creating baby pools?",
      answer:
        "Yes, for those seeking an ad-free experience with unlimited category creation, we offer a premium version. This version is available for a nominal fee of $1.99 USD. It allows you to create baby pools without any ads and with the flexibility to add as many categories as you like, enhancing the overall experience for both the organizer and the participants.",
    },
    {
      question: "Is there an app available for baby pools?",
      answer:
        "An app for baby pools is currently in development and will be available soon for both iOS and Android platforms. However, it's important to note that the app will be designed specifically for baby pool creators. Participants who wish to make guesses will continue to do so through our website. Please sign up to the waitlist below to be the first to hear about new releases.",
    },
    {
      question: "How is the winner determined in a baby pool?",
      answer:
        "The winner is usually the person who makes the most accurate predictions. Scoring systems may vary, with points awarded for each correct guess. The pool creator can determine how many points each category is worth.",
    },
    {
      question: "How can I provide feedback about the baby pool?",
      answer:
        "We welcome your feedback to improve our service! You can provide feedback by entering a comment in the 'Contact Us' section directly below. We value your suggestions and take them seriously in our ongoing effort to enhance your experience.",
    },
  ];

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const renderFAQs = () => {
    return faqs.map((faq, index) => (
      <Accordion
        key={index}
        onChange={handleChange(`panel${index}`)}
        expanded={expanded === `panel${index}`}
        sx={{
          width: "100%",
          boxShadow: "none",
          backgroundColor: "transparent",
          "&:not(:last-child)": {
            borderBottom: 1,
            borderColor: "divider",
          },
          "&:before": {
            display: "none",
          },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel${index}a-content`}
          id={`panel${index}a-header`}
        >
          <Typography sx={{ fontWeight: "bold", width: "100%" }}>
            {faq.question}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>{faq.answer}</Typography>
        </AccordionDetails>
      </Accordion>
    ));
  };

  return (
    <Box
      sx={{
        minHeight: "65vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#f5f5f5",
        // padding: { xs: 2, sm: 3, md: 4 },
        width: "90%",
        margin: "auto",
      }}
    >
      <Typography
        variant="h5"
        sx={{
          //   color: "#5cb85c",
          marginBottom: 4,
          textAlign: "center",
          fontWeight: "bold",
        }}
      >
        Frequently Asked Questions
      </Typography>
      {renderFAQs()}
    </Box>
  );
}

export default FAQ;
