import React from "react";
import { Card, CardContent, Typography } from "@mui/material";

const Details = ({ poolDetails }) => {
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };

    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <Card sx={{ m: 2 }}>
      <CardContent>
        <Typography variant="h5" gutterBottom>
          Details
        </Typography>
        <Typography variant="body1">{poolDetails.name}</Typography>
        <Typography variant="body2" sx={{ mt: 1 }}>
          {poolDetails.description}
        </Typography>
        <Typography variant="body2" sx={{ mt: 2, fontStyle: "italic" }}>
          Due Date: {formatDate(poolDetails.dueDate)}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default Details;
