import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  Grid,
  CircularProgress,
  useTheme,
} from "@mui/material";
import { useSelector } from "react-redux";
import { fetchUser } from "../../redux/actions/user";
import { API_URL } from "../../utils/constants";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const PoolCard = ({ pool }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`/pools/${pool.uniqueId}`, { state: { pool } });
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };

    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <Card
      sx={{
        width: 300,
        m: 2,
        boxShadow: theme.shadows[4],
        "&:hover": {
          boxShadow: theme.shadows[6],
        },
        backgroundColor: theme.palette.background.paper,
        cursor: "pointer",
      }}
      onClick={handleClick}
    >
      <CardContent>
        <Typography variant="h6" gutterBottom>
          {pool.name}
        </Typography>
        <Typography variant="body2" fontStyle="italic">
          {formatDate(pool.dueDate)}
        </Typography>
        <Typography variant="body2">{pool.description}</Typography>
      </CardContent>
    </Card>
  );
};

const Pools = ({ navigation }) => {
  const user = useSelector(fetchUser);
  const [pools, setPools] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const fetchPools = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${API_URL}/api/v0/pools`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      setPools(response.data || []);
    } catch (error) {
      console.error("Error fetching pools:", error);
    } finally {
      setLoading(false);
    }
  }, [user.token]);

  useEffect(() => {
    if (!user) {
      navigate("/");
    }
    fetchPools();
  }, [fetchPools, navigate, user]);

  const theme = useTheme();

  return (
    <Box
      sx={{
        flexGrow: 1,
        p: theme.spacing(3),
        backgroundColor: theme.palette.grey[100],
      }}
    >
      {loading || !user ? (
        <CircularProgress
          sx={{
            mb: 2,
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        />
      ) : (
        <React.Fragment>
          <Typography variant="h4" sx={{ textAlign: "center", mb: 2 }}>
            Welcome {user.lastLogin ? "Back," : ""} {user.firstName}!
          </Typography>
          {pools.length === 0 ? (
            <Typography variant="h6">No pools available yet!</Typography>
          ) : (
            <Grid
              container
              spacing={2}
              justifyContent="center"
              sx={{ overflowX: "auto", p: 2 }}
            >
              {pools.map((pool, index) => (
                <Grid item key={index}>
                  <PoolCard pool={pool} />
                </Grid>
              ))}
            </Grid>
          )}

          <Typography
            variant="subtitle1"
            sx={{ mt: 3, mb: 2, textAlign: "center" }}
          >
            Join in a playful guessing game with loved ones, predicting the
            special details of your upcoming bundle of joy. Share in the
            excitement and make lasting memories together.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              navigate("/create");
            }}
            sx={{
              mb: 2,
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            Create a New Pool
          </Button>
        </React.Fragment>
      )}
    </Box>
  );
};

export default Pools;
