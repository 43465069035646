import React, { useState, useEffect, useCallback } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { fetchUser, loginSuccess } from "../../redux/actions/user";
import { API_URL } from "../../utils/constants";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Switch,
  FormGroup,
  FormControlLabel,
  Modal,
  Typography,
  FormHelperText,
  CircularProgress,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);

const poolSchema = yup.object().shape({
  name: yup.string().required("Pool name is required"),
  description: yup.string().required("Description is required"),
  dueDate: yup.date().required("Due date is required"),
  scoring: yup.array().of(
    yup.object().shape({
      category: yup.string().required("Label is required"),
      score: yup
        .number()
        .typeError("Score must be a number")
        .required("Score is required")
        .min(1, "Score must be greater than 0"),
      type: yup.string().required("Type is required"),
    })
  ),
});

const CreatePools = () => {
  const user = useSelector(fetchUser);
  const [modalVisible, setModalVisible] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const editPoolData = location.state?.poolDetails;

  const isProUser = user ? user.paid || user.subscription === "pro" : false;

  const [isPrivate, setIsPrivate] = useState(
    editPoolData && editPoolData.password ? true : false
  );
  const [password] = useState(
    editPoolData && editPoolData.password ? "*********" : ""
  );

  useEffect(() => {
    const updateUser = async () => {
      const updatedUserData = await axios.get(`${API_URL}/api/v0/users`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
          "Content-Type": "application/json",
        },
      });

      if (JSON.stringify(updatedUserData.data) !== JSON.stringify(user)) {
        dispatch(loginSuccess(updatedUserData.data));
      }
    };
    updateUser();
    // eslint-disable-next-line
  }, []);

  const handlePaymentSuccess = useCallback(async () => {
    try {
      const payload = {
        userId: user._id,
        paid: true,
        subscription: "pro",
      };

      const response = await axios.put(`${API_URL}/api/v0/users`, payload, {
        headers: {
          Authorization: `Bearer ${user.token}`,
          "Content-Type": "application/json",
        },
      });

      if (response.status === 200) {
        const updatedUserData = await axios.get(`${API_URL}/api/v0/users`, {
          headers: {
            Authorization: `Bearer ${user.token}`,
            "Content-Type": "application/json",
          },
        });

        dispatch(loginSuccess(updatedUserData.data));
      } else {
      }
    } catch (error) {
      console.error("Error updating user status:", error);
    }
  }, [dispatch, user._id, user.token]);

  useEffect(() => {
    if (!user) {
      navigate("/");
    }
  });

  useEffect(() => {
    const verifyPayment = async () => {
      const params = new URLSearchParams(location.search);
      const session_id = params.get("session_id");
      if (!session_id) {
        return;
      }

      try {
        const response = await axios.get(
          `${API_URL}/api/v0/stripe/payment?session_id=${session_id}`,
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.data) {
          handlePaymentSuccess();
        }
      } catch (error) {
        console.error("Error verifying payment:", error);
        // Handle error
      }
    };

    verifyPayment();
  }, [location, navigate, handlePaymentSuccess, user.token]);

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const categoryTypes = [
    "Sex",
    "Letter",
    "Date",
    "Time",
    "Weight (kg)",
    "Weight (lb)",
    "Length (in)",
    "Length (cm)",
    "Name Length",
    "Hair Color",
    "Eye Color",
  ];

  const toggleSwitch = () => setIsPrivate((previousState) => !previousState);

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(poolSchema),
    defaultValues: {
      scoring: isProUser
        ? editPoolData
          ? editPoolData.scoring
          : [
              { category: "", score: "15", type: "Date" },
              { category: "", score: "10", type: "Sex" },
            ]
        : [{ category: "", score: "10", type: "Sex" }],
      dueDate: new Date(),
      password: "",
    },
  });

  useEffect(() => {
    if (editPoolData) {
      const formattedData = {
        ...editPoolData,
        scoring: editPoolData.scoring.map((item) => ({
          ...item,
          score: item.score.toString(),
        })),
        dueDate: new Date(editPoolData.dueDate),
      };
      reset(formattedData);
    }
  }, [editPoolData, reset]);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "scoring",
  });

  const onSubmit = async (data) => {
    try {
      if (password && password.trim() !== "") {
        data.password = password;
      }

      const url = editPoolData
        ? `${API_URL}/api/v0/pools/${editPoolData._id}`
        : `${API_URL}/api/v0/pools`;

      const method = editPoolData ? "put" : "post";

      const response = await axios[method](url, data, {
        headers: {
          Authorization: `Bearer ${user.token}`,
          "Content-Type": "application/json",
        },
      });
      if (response.data) {
        navigate("/pools");
      }
    } catch (error) {
      console.error("Error saving pool:", error);
      alert("Error saving pool");
    }
  };

  const handleAppend = async (event) => {
    // event.preventDefault();

    if (isProUser || fields.length < 1) {
      append({ category: "", score: "", type: "Sex" });
    } else {
      //   handleCardPayment();
      try {
        const response = await axios.get(`${API_URL}/api/v0/stripe/intent`, {
          headers: {
            Authorization: `Bearer ${user.token}`,
            "Content-Type": "application/json",
          },
        });
        console.log(response.data);
        const sessionId = response.data.id;
        const stripe = await stripePromise;
        stripe.redirectToCheckout({ sessionId });
      } catch (error) {
        console.error("Error redirecting to Stripe Checkout:", error);
        // Handle error (show message to user, etc.)
      }
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      {!user ? (
        <CircularProgress
          sx={{
            mb: 2,
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* Name field */}
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Pool Name"
                variant="outlined"
                error={!!errors.name}
                helperText={errors.name?.message}
                fullWidth
                margin="normal"
              />
            )}
          />
          <Controller
            name="description"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Description"
                variant="outlined"
                error={!!errors.description}
                helperText={errors.description?.message}
                fullWidth
                margin="normal"
              />
            )}
          />
          <Controller
            name="dueDate"
            control={control}
            render={({ field: { onChange, value } }) => (
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label={`Due Date`}
                  value={value}
                  onChange={onChange}
                  minDate={new Date()}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      margin="normal"
                      error={!!errors.dueDate}
                      helperText={errors.dueDate?.message}
                    />
                  )}
                />
              </LocalizationProvider>
            )}
          />
          {/* Switch for private/public */}
          <FormGroup>
            <FormControlLabel
              control={<Switch checked={isPrivate} onChange={toggleSwitch} />}
              label="Make Pool Private"
            />
          </FormGroup>
          {/* Password field (if private) */}
          {isPrivate && (
            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Password"
                  type="password"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  error={!!errors.password}
                  helperText={errors.password?.message}
                />
              )}
            />
          )}
          {fields.map((item, index) => (
            <Box
              key={item.id}
              sx={{
                backgroundColor: "#fff",
                borderRadius: 5,
                padding: 2,
                marginBottom: 2,
                shadowColor: "#000",
                shadowOffset: { width: 0, height: 2 },
                shadowRadius: 5,
                elevation: 3,
              }}
            >
              <Box
                xs={{
                  flexDirection: "row",
                  alignItems: "center",
                  marginBottom: 10,
                }}
              >
                <Box xs={{ flex: 1, marginRight: 10 }}>
                  <Controller
                    name={`scoring[${index}].category`}
                    control={control}
                    render={({ field }) => (
                      <FormControl fullWidth margin="normal">
                        <TextField
                          error={
                            errors &&
                            errors.scoring &&
                            errors.scoring[index] &&
                            errors.scoring[index].category &&
                            !!errors.scoring[index].category
                          }
                          helperText={
                            errors &&
                            errors.scoring &&
                            errors.scoring[index] &&
                            errors.scoring[index].category &&
                            errors.scoring[index].category.message
                          }
                          {...field}
                          label="Label"
                        />
                      </FormControl>
                    )}
                  />
                </Box>
                <Box xs={{ flex: 1, marginRight: 10 }}>
                  <Controller
                    name={`scoring[${index}].type`}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <FormControl fullWidth margin="normal">
                        <InputLabel>Type</InputLabel>
                        <Select
                          value={value}
                          onChange={onChange}
                          label="Type"
                          error={
                            !!(errors.scoring && errors.scoring[index]?.type)
                          }
                        >
                          {categoryTypes.map((type) => (
                            <MenuItem key={type} value={type}>
                              {type}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText>
                          {errors.scoring &&
                            errors.scoring[index]?.type?.message}
                        </FormHelperText>
                      </FormControl>
                    )}
                  />
                </Box>
                <Box xs={{ flex: 1, marginRight: 10 }}>
                  <Controller
                    control={control}
                    name={`scoring[${index}].score`}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <FormControl fullWidth margin="normal">
                        <TextField
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                          placeholder="Score"
                          type="number"
                          error={
                            !!(errors.scoring && errors.scoring[index]?.score)
                          }
                          helperText={
                            errors.scoring &&
                            errors.scoring[index]?.score?.message
                          }
                          fullWidth
                          margin="normal"
                        />{" "}
                      </FormControl>
                    )}
                  />
                  <Button
                    type="submit"
                    variant="contained"
                    color="error"
                    sx={{ mr: 2 }}
                    onClick={() => remove(index)}
                  >
                    Remove
                  </Button>
                </Box>
              </Box>
            </Box>
          ))}
          {/* Submit button */}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{ mr: 2 }}
            onClick={handleAppend}
          >
            {isProUser ? "Add Scoring Field" : "Upgrade to Add More"}
          </Button>{" "}
          <Button type="submit" variant="contained" color="primary">
            Save Pool
          </Button>
        </form>
      )}
      {/* Modal for scoring info */}
      <Modal
        open={modalVisible}
        onClose={() => setModalVisible(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Scoring Information
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {/* Scoring details */}
          </Typography>
        </Box>
      </Modal>
    </Box>
  );
};

export default CreatePools;
