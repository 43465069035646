import React, { forwardRef, useState } from "react";
import emailjs from "emailjs-com";
import { Box, TextField, Button, Typography } from "@mui/material";

const Waitlist = forwardRef((props, ref) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs
      .send(
        "service_lycmflm",
        "template_vsb2aht",
        formData,
        "6ilttmuLTqKtFBUeZ"
      )
      .then(
        (result) => {
          setSuccessMessage(
            "Message sent successfully! Your feedback is important to us. If necessary, someone will contact you within 2-3 days."
          );

          setFormData({
            name: "",
            email: "",
            message: "",
          });
        },
        (error) => {
          setErrorMessage("An error occurred. Please try again later.");
        }
      );
  };

  return (
    <Box
      component="form"
      sx={{
        backgroundColor: "#fff",
        padding: 2, // equivalent to 20px
        // width: "50%",
        marginLeft: "auto",
        marginRight: "auto",
        minHeight: "40vh",
      }}
      onSubmit={handleSubmit}
      ref={ref}
    >
      <Typography
        variant="h6"
        sx={{ color: "#d9534f", marginBottom: 2, marginTop: 2 }}
      >
        Contact Us
      </Typography>
      {successMessage && (
        <Typography variant="body1" sx={{ color: "green", marginBottom: 2 }}>
          {successMessage}
        </Typography>
      )}
      {errorMessage && (
        <Typography variant="body1" sx={{ color: "red", marginBottom: 2 }}>
          {errorMessage}
        </Typography>
      )}
      <TextField
        fullWidth
        name="name"
        label="Your Name"
        variant="outlined"
        margin="normal"
        value={formData.name}
        onChange={handleChange}
      />
      <TextField
        fullWidth
        name="email"
        label="Your Email"
        variant="outlined"
        margin="normal"
        value={formData.email}
        onChange={handleChange}
      />
      <TextField
        fullWidth
        name="message"
        label="Your Message"
        variant="outlined"
        margin="normal"
        multiline
        rows={4}
        value={formData.message}
        onChange={handleChange}
      />
      <Button
        type="submit"
        variant="contained"
        color="primary"
        sx={{ marginTop: 2 }}
        disabled={!formData.email || !formData.name || !formData.message}
      >
        Send Message
      </Button>
    </Box>
  );
});

export default Waitlist;
